import React from "react";
import img from "../img/logo.svg";
import { Menu } from "./Menu";

import { gsap } from "gsap";

export const Header = (props) => {
  const { selectedPageLabel, setSelectedPageLabel } = props;
  const headerRef = React.useRef(null);

  React.useEffect(() => {
    if (headerRef.current) {
      gsap.fromTo(
        headerRef.current,
        { y: -200 },
        { y: 0, duration: 1, ease: "back.out" }
      );
    }
  }, []);

  return (
    <div className="header-wrapper" ref={headerRef}>
      <div
        className="logo-wrapper"
        onClick={() => {
          setSelectedPageLabel("home");
        }}
      >
        <img src={img} />
      </div>
      <Menu
        selectedPageLabel={selectedPageLabel}
        setSelectedPageLabel={setSelectedPageLabel}
      />
    </div>
  );
};
