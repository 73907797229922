import React from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(useGSAP);

export const Homepage = (props) => {
  const {
    nextComponent,
    setRenderedComponent,
    setSelectedPageLabel,
  } = props;
  //   const { exitAnimationTimeline } = props;

  const animation = React.useRef(gsap.timeline());

  const WAIT_FOR_OTHER_ANIMATIONS = 0;

  //   const container = React.useRef(null);

  useGSAP(() => {
    gsap.fromTo(
      contentRef.current,
      { opacity: 0, y: -250 },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,

        ease: "back.out",
      }
    );

    gsap.fromTo(
      buttonRef.current,
      { opacity: 0, y: -100 },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,

        delay: 0.15,
        ease: "back.out",
      }
    );
  });

  React.useEffect(() => {
    if (nextComponent !== "home") {
      gsap.to(buttonRef.current, {
        opacity: 0,
        y: -100,
        duration: 0.5,
        ease: "back.out",
      });

      gsap.to(contentRef.current, {
        opacity: 0,
        y: -200,
        duration: 0.5,
        onComplete: () => {
          setRenderedComponent(nextComponent);
        },
      });
    }
  }, [nextComponent]);

  const contentRef = React.useRef(null);
  const buttonRef = React.useRef(null);

  return (
    <div className="homepage-wrapper">
      <div className="homepage-title" ref={contentRef}>
        Livrăm soluții web pentru cele mai mari provocări
        ale afacerii tale
      </div>
      <div ref={buttonRef} id="contact-button-wrapper">
        <div
          className="contact-button"
          onClick={() => setSelectedPageLabel("contact")}
        >
          Contactează-ne!
        </div>
      </div>
    </div>
  );
};
