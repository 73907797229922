import React from "react";

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

export const Contact = (props) => {
  const { nextComponent, setRenderedComponent } = props;

  const contactTitleRef = React.useRef(null);

  useGSAP(() => {
    gsap.fromTo(
      contactTitleRef.current,
      { opacity: 0, y: -250 },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,

        ease: "back.out",
      }
    );

    gsap.fromTo(
      ".contact-details",
      { opacity: 0, y: -100 },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,
        stager: 0.2,
        delay: 0.15,
        ease: "back.out",
      }
    );
  });

  React.useEffect(() => {
    if (nextComponent !== "contact") {
      gsap.to(".contact-details", {
        opacity: 0,
        y: -100,
        stager: 0.2,
        ease: "back.out",
        duration: 0.5,
      });

      gsap.to(contactTitleRef.current, {
        opacity: 0,
        y: -50,
        duration: 0.5,
        onComplete: () => {
          setRenderedComponent(nextComponent);
        },
      });
    }
  }, [nextComponent]);

  return (
    <div className="contact-wrapper">
      <div className="contact-title" ref={contactTitleRef}>
        Contactează-ne și hai să colaborăm la următorul tău
        proiect!
      </div>
      <div></div>
      <div className="contact-details">
        <div className="contact-details-entry">
          <div>email:</div>
          <div className="contact-value">
            colaborari@web-experiences.ro
          </div>
        </div>
        <div className="contact-details-entry">
          <div>whatsapp:</div>
          <div
            className="contact-value"
            onClick={() => {
              window.open(
                "https://wa.me/40759771479",
                "_BLANK"
              );
            }}
          >
            0759771479
          </div>
        </div>
      </div>
    </div>
  );
};
