import React from "react";

import one from "../img/1.webp";
import two from "../img/2.webp";
import four from "../img/4.webp";
import five from "../img/5.webp";
import six from "../img/6.webp";

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

export const Products = (props) => {
  const { nextComponent, setRenderedComponent } = props;

  const productsRef = React.useRef(null);
  useGSAP(() => {
    gsap.fromTo(
      productsRef.current,
      { opacity: 0, y: -250 },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,

        ease: "back.out",
      }
    );

    gsap.from(".fade-out", {
      y: 200,
      duration: 0.5,
      delay: 0.5,
    });
  });

  React.useEffect(() => {
    if (nextComponent !== "products") {
      gsap.to(".card-wrapper", {
        opacity: 0,
        y: -100,
        stagger: 0.2,
        ease: "back.out",
      });

      gsap.to(".fade-out", {
        y: 200,
        duration: 0.8,
        // delay: 0.5,
        ease: "back.out",
      });

      gsap.to(productsRef.current, {
        opacity: 0,
        y: -50,
        duration: 0.5,
        ease: "back.out",
        onComplete: () => {
          setRenderedComponent(nextComponent);
        },
      });
    }
  }, [nextComponent]);

  return (
    <div className="products-wrapper">
      <div className="products-title" ref={productsRef}>
        Mai jos găsești câteva din modurile prin care te
        putem ajuta să-ți duci afacerea la următorul nivel
      </div>

      {/* <CardsList cards={cards} /> */}

      <Cards />
      <div className="fade-out" />
    </div>
  );
};

const Cards = () => {
  const cards = [
    {
      index: 0,
      imgSrc: one,
      label: "Website de prezentare",
      customImageStyles: {
        // marginLeft: "-25px",
      },
      id: "cardone",
      description: [
        "realizăm websiteuri de prezentare pentru o largă varietate de produse și servicii",
        "ne ocupăm de toată logistica necesară pentru a avea websiteul funcțional în cel mai scurt timp (domeniu, hosting, configurări)",
        "te ajutăm cu administrarea conținutului websiteului",
        "pretabil pentru situațiile ce nu necesită actualizări frecvente ale informațiilor publicate sau pentru cei care, din diferite motive, doresc să delege partea de administrare a conținutului",
      ],
    },
    {
      index: 1,
      imgSrc: two,
      label: "Website + CMS",
      customImageStyles: {
        // marginLeft: "-35px",
      },
      id: "cardtwo",
      description: [
        "funcționalitatea CMS (Content Management System) îți oferă posibilitatea de a-ți gestiona singur conținutul websiteului după bunul plac",
        "ai control total asupra websiteului; poți adăuga conținut, poți edita conținutul existent; poți șterge sau ascunde temporar anumite pagini sau secțiuni ale websiteului",
        "pretabil pentru websiteuri unde conținutul este dinamic: bloguri personale, websiteuri culinare, websiteuri de nișă, etc",
      ],
    },
    {
      index: 2,
      imgSrc: four,
      label: "Platformă multi-user",
      customImageStyles: {},
      id: "cardthree",
      description: [
        "aplicațiile web de tip multi-user sunt ideale pentru serviciile online pe bază de abonament lunar",
        "anumite secțiuni ale websiteului pot fi accesibile publicului larg; alte secțiuni, premium, sunt accesibile doar userilor autentificați (pe bază de username și parolă)",
        "exemple: website pentru antrenori personali de sport, platformă de e-learning, platformă de crowdsourcing, platformă de fundraising, etc",
      ],
    },

    {
      index: 3,
      imgSrc: five,
      label: "Platformă backoffice",
      customImageStyles: {},
      id: "cardfour",
      description: [
        "o platformă de backoffice realizată exact pe nevoile firmei tale îți poate reduce drastic cheltuielile anuale printr-o planificare mai eficientă a tuturor resurselor",
        "ajută la automatizarea activităților repetitive",
        "pretabilă pentru gestionarea concediilor, planificarea taskurilor, administrarea contractelor și facturilor, generarea de rapoarte, etc",
      ],
    },

    {
      index: 4,
      imgSrc: six,
      label: "Aplicație web specifică",
      customImageStyles: {},
      id: "cardfive",
      description: [
        "având la îndemână atât de multă tehnologie doar imaginația ne poate limita",
        "suntem încrezători că putem găsit o soluție pentru orice nevoie de aplicație ai avea",
        "pretabilă pentru soluții din zona de agri-tech, fintech, healthcare, smart home, etc",
      ],
    },
  ];

  return (
    <div className="cards-wrapper">
      {cards.map((card) => {
        return <Card {...card} key={card.index} />;
      })}
      <div className="cards-wrapper-empty-space" />
    </div>
  );
};

const CARD_HEIGHT = 200;
const cardWidth = 150;

const Card = (props) => {
  const {
    id,
    index,
    imgSrc,
    label,
    description,
    customImageStyles,
    spaceBetweenCards,
    setSelectedCard,
    isInFocus,
  } = props;

  const cardRef = React.useRef(null);
  const cardLabelWrapper = React.useRef(null);
  const cardDescriptionWrapperRef = React.useRef(null);
  const imgRef = React.useRef(null);

  React.useEffect(() => {
    const timeline = gsap.timeline(
      // { delay: index * 0.5 }
      { delay: index * 0.5 }
    );

    timeline.add(
      gsap.fromTo(
        cardRef.current,
        { autoAlpha: 0, x: -100 },
        {
          autoAlpha: 1,
          x: 0,
          duration: 0.5,
          ease: "back.out",
        }
      ),
      0
    );

    timeline.add(
      gsap.from(
        cardLabelWrapper.current,
        { height: "0%", opacity: 0, ease: "back.out" }
        // { height: "35%", opacity: 1, }
      ),
      1
    );

    timeline.add(
      gsap.fromTo(
        cardDescriptionWrapperRef.current,
        {
          autoAlpha: 0,
          x: 500,
        },
        {
          autoAlpha: 1,
          x: 0,
          duration: 1,
          ease: "back.out",
        }
      ),
      0
    );

    timeline.add(
      gsap.fromTo(
        `.card${id}__paragraph`,
        {
          autoAlpha: 0,
          y: -50,
        },
        {
          duration: 1,
          autoAlpha: 1,
          y: 0,
          stagger: 0.1,
          ease: "back.out",
        }
      )
    );
  }, []);

  return (
    <div className="card-wrapper" ref={cardRef}>
      <div id={id} className="card-left-wrapper">
        <div
          className="card-image-wrapper"
          style={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            // position: "absolute",
            borderRadius: "10px 0 0 10px",
          }}
        >
          <img
            ref={imgRef}
            src={imgSrc}
            style={{
              ...customImageStyles,
            }}
          />
        </div>
        <div
          className="card-label-wrapper"
          ref={cardLabelWrapper}
        >
          {label}
        </div>
      </div>
      <div
        ref={cardDescriptionWrapperRef}
        className="card-description-wrapper"
        style={{
          // display: isInFocus ? "flex" : "none",
          display: "flex",
          // position: "absolute",
          // left: 150,
          // x: 150,

          flex: 1,
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "space-between",
          borderRadius: "0 10px 10px 0 ",

          background: "#292929",
          padding: "16px 36px",
          boxSizing: "border-box",
          fontSize: "14px",

          // width: 0,
          // overflow: "hidden",
          // padding: 0,
        }}
      >
        {description.map((descriptionParagraph, index) => {
          return (
            <div
              key={`card${descriptionParagraph.index}_${index}`}
              className={`card${id}__paragraph paragraph`}
            >
              <li>{descriptionParagraph}</li>
            </div>
          );
        })}
      </div>
    </div>
  );
};
