import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Button } from "@mui/material";

import { Homepage } from "./Homepage";
import { Products } from "./Products";
import { Contact } from "./Contact";

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

export const Menu = (props) => {
  const { selectedPageLabel, setSelectedPageLabel } = props;

  return (
    <>
      <div className="desktop-menu">
        <DesktopMenuItem
          label="Acasă"
          setSelectedPageLabel={setSelectedPageLabel}
          pageLabel="home"
          isSelected={selectedPageLabel === "home"}
        />
        <DesktopMenuItem
          label="Produsele noastre"
          setSelectedPageLabel={setSelectedPageLabel}
          pageLabel="products"
          isSelected={selectedPageLabel === "products"}
        />
        <DesktopMenuItem
          label="Contact"
          setSelectedPageLabel={setSelectedPageLabel}
          pageLabel="contact"
          isSelected={selectedPageLabel === "contact"}
        />
        {/* <DesktopMenuItem
          label="Blog"
          setSelectedPageLabel={setSelectedPageLabel}
          pageLabel="blog"
          isSelected={selectedPageLabel === "blog"}
        /> */}
      </div>
      <MobileMenu {...props} />
    </>
  );
};

const DesktopMenuItem = (props) => {
  const {
    label,
    setSelectedPageLabel,
    pageLabel,
    isSelected,
  } = props;

  const animation = React.useRef(
    gsap.timeline({ paused: true })
  );

  const itemRef = React.useRef(null);
  const underlineRef = React.useRef(null);

  const initialRender = React.useRef(
    gsap.timeline({
      paused: true,
    })
  );

  const clickAnimation = React.useRef(
    gsap.timeline({
      paused: true,
    })
  );

  const animationInProgress = React.useRef(false);

  const backToDefaultAnimation = React.useRef(
    gsap.timeline({ paused: true })
  );

  useGSAP(() => {
    clickAnimation.current.add(
      gsap.to(itemRef.current, {
        y: -3,
        duration: 0.2,
        color: "white",
      })
    );

    clickAnimation.current.add(
      gsap.to(underlineRef.current, {
        width: "100%",
        duration: 0.2,
      })
    );

    initialRender.current.add(
      gsap.to(itemRef.current, {
        y: -3,
        duration: 0.2,
        color: "white",
        delay: 1,
      }),
      0
    );

    initialRender.current.add(
      gsap.to(underlineRef.current, {
        width: "100%",
        duration: 0.2,
        delay: 1,
      }),
      0
    );

    animation.current.add(
      gsap.to(
        itemRef.current,
        // { y: 0, color: "white" },
        {
          y: -3,
          duration: 0.2,
          color: "#ed74ed",
          // ease: "back.out",
        }
      )
    );

    // remove underline
    // move item to y:0
    // make the color white

    backToDefaultAnimation.current.add(
      gsap.fromTo(
        itemRef.current,
        { y: -3 },
        {
          y: 0,
          color: "white",
          duration: 0.2,
        }
      ),
      0
    );

    backToDefaultAnimation.current.add(
      gsap.to(underlineRef.current, {
        width: "0%",
        duration: 0.2,
      }),
      0
    );
  });

  React.useEffect(() => {
    if (isSelected) {
      initialRender.current.play(0);
      animationInProgress.current = true;
    }
  }, []);

  //   React.useEffect(() => {
  //     if (isSelected) {
  //       gsap.to(itemRef.current, {
  //         y: -3,
  //         duration: 0.2,
  //         delay: 1.5,
  //       });

  //       gsap.to(underlineRef.current, {
  //         width: "100%",
  //         duration: 0.2,
  //         delay: 1.5,
  //       });
  //     }
  //   }, []);

  React.useEffect(() => {
    if (animationInProgress.current) {
      animationInProgress.current = false;
      return;
    }

    if (isSelected) {
      clickAnimation.current.play(0);
    } else {
      backToDefaultAnimation.current.play(0);
      //   clickAnimation.current.reverse();
      //   initialRender.current.reverse();
      //   animation.current.reverse();
      //   gsap.to(itemRef.current, {
      //     y: 0,
      //     duration: 0.2,
      //   });
      //   gsap.to(underlineRef.current, {
      //     width: "0%",
      //     duration: 0.2,
      //   });
    }
  }, [isSelected]);

  return (
    <div
      className="desktop-menu-item"
      onClick={() => {
        setSelectedPageLabel(pageLabel);
      }}
      onMouseEnter={() => {
        if (!isSelected) {
          animation.current.play(0);
        }
      }}
      onMouseLeave={() => {
        if (!isSelected) {
          animation.current.reverse();
        }
      }}
    >
      <div ref={itemRef}>{label}</div>
      <div
        ref={underlineRef}
        className="desktop-menu-item-underline"
      />
    </div>
  );
};

const MobileMenu = (props) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const { selectedPageLabel, setSelectedPageLabel } = props;

  const menuWrapper = React.useRef(null);

  const entryTimeline = React.useRef(
    gsap.timeline({ paused: true })
  );

  const exitTimeline = React.useRef(
    gsap.timeline({ paused: true })
  );

  useGSAP(() => {
    entryTimeline.current.add(
      gsap.fromTo(
        menuWrapper.current,
        {
          autoAlpha: 0,
        },
        { autoAlpha: 1, duration: 0.2 }
      ),
      0
    );
    entryTimeline.current.add(
      gsap.from(".mobile-menu-item", {
        autoAlpha: 0,
        x: 50,
        duration: 0.3,
        stagger: 0.1,
      }),
      0
    );

    exitTimeline.current.add(
      gsap.to(menuWrapper.current, {
        autoAlpha: 0,
        // opacity: 0,
        duration: 1,
      })
    );
  });

  React.useEffect(() => {
    if (isExpanded) {
      entryTimeline.current.timeScale(1).play(0);
    } else {
      //   exitTimeline.current.play(0);
      entryTimeline.current.timeScale(2).reverse();
    }
  }, [isExpanded]);

  React.useEffect(() => {
    if (selectedPageLabel === "products") {
      setTimeout(() => {
        const divElement = document.querySelector(
          ".products-wrapper"
        );

        const handleScroll = () => {
          //   exitTimeline.current.play(0);
          //   entryTimeline.current.reverse();
          setIsExpanded(false);
          // Add your scroll handling logic here
        };

        // Add scroll event listener
        divElement?.addEventListener(
          "scroll",
          handleScroll
        );

        console.log("adding scroll event");
        // Cleanup function to remove the scroll event listener
        return () => {
          console.log("removing listener scroll");
          divElement?.removeEventListener(
            "scroll",
            handleScroll
          );
        };
      }, 2000);
    }
  }, [selectedPageLabel]);

  return (
    <div className="mobile-menu">
      <div onClick={() => setIsExpanded(!isExpanded)}>
        <MenuIcon
          sx={{
            color: "white",
            width: "24px",
            height: "24px",
          }}
        />
      </div>
      <div
        ref={menuWrapper}
        style={{
          //   display: "none",
          opacity: 0,
          visibility: "hidden",
          //   opacity: 0,
          //   display: isExpanded ? "block" : "none",
          position: "absolute",
          background: "#1f1f1f",
          color: "white",
          top: "44px",
          right: "0px",
          fontSize: "14px",
          //   bottom: 0,
          padding: "12px 32px",
          border: "2px solid #6a6a6a",
          borderRadius: "8px 0px 8px 8px",
          //   color,
        }}
      >
        <div
          className="mobile-menu-item"
          onClick={() => {
            setSelectedPageLabel("home");
            setIsExpanded(false);
          }}
        >
          Acasă
        </div>
        <div
          className="mobile-menu-item"
          onClick={() => {
            setSelectedPageLabel("products");
            setIsExpanded(false);
          }}
        >
          Produsele noastre
        </div>
        <div
          className="mobile-menu-item"
          onClick={() => {
            setSelectedPageLabel("contact");
            setIsExpanded(false);
          }}
        >
          Contact
        </div>
      </div>
    </div>
  );
};
